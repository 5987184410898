import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import showdown from 'showdown'

export const FnPageTemplate = ({ title, page_name, description, image, un_goal, content }) => {
  const converter = new showdown.Converter();
  const backgroundImage = typeof image === 'string' ? image : image.childImageSharp.fluid.src

  const un_goal_image1 = typeof un_goal.image_1 === 'string' ? un_goal.image_1 : un_goal.image_1.childImageSharp.fluid.src
  const un_goal_image2 = typeof un_goal.image_2 === 'string' ? un_goal.image_2 : un_goal.image_2.childImageSharp.fluid.src
  const un_goal_image3 = typeof un_goal.image_3 === 'string' ? un_goal.image_3 : un_goal.image_3.childImageSharp.fluid.src
  const un_goal_image4 = typeof un_goal.image_4 === 'string' ? un_goal.image_4 : un_goal.image_4.childImageSharp.fluid.src
  const un_goal_image5 = typeof un_goal.image_5 === 'string' ? un_goal.image_5 : un_goal.image_5.childImageSharp.fluid.src
  const un_goal_image6 = typeof un_goal.image_6 === 'string' ? un_goal.image_6 : un_goal.image_6.childImageSharp.fluid.src

   const renderFooterAction = page_name => {

    const pages = {
      home: {
        display: 'Hjem',
        next: '/stiftelsen'
      },
      stiftelsen: {
        display: 'Stiftelsen',
        next: '/styret',
        prev: '/'
      },
      styret: {
        display: 'Styret',
        next: '/formal',
        prev: '/stiftelsen'
      },
      formal: {
        display: 'Formål',
        next: '/fn',
        prev: '/styret'
      },
      fn: {
        display: 'FNs bærekraftsmål',
        next: '/vedtekter',
        prev: '/formal'
      },
      vedtekter: {
        display: 'Vedtekter',
        next: '/samarbeidspartnere',
        prev: '/fn'
      },
      samarbeidspartnere: {
        display: 'Samarbeidspartnere',
        next: '/stiftelsen',
        prev: '/vedtekter'
      },
    }

    const page = pages[page_name];

    return (
      <div className='footer-action'>
        <Link to={page.prev}  disabled={page.prev == undefined}  className={ `prev ${ page.prev == undefined ? 'disabled' : '' }` } >
          {"<  "}
        </Link>

        <span>{ page.display }</span>

        <Link to={page.next} disabled={page.next == undefined}  className={ `next ${ page.next == undefined ? 'disabled' : '' }` }>
          {"  >"}
        </Link>
      </div>
    )
  }

  return (
  <main id="index" className="foundation" style={{ backgroundImage: `url(${backgroundImage})` }}>
    <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
    </Helmet>
    <Navbar page_name={page_name}/>

    <section className="hero is-fullheight">
      <div className="mt-12">
        <div className="container main-section">
          <div className="columns">
            <div className="column">
            </div>

            <div className="column is-three-quarters">
                <div className="content has-text-white">
                    <h1>FNs bærekraftsmål</h1>

                    <p>
                      FN ́s seks første bærekraftmål som Stiftelsen Signe Marie
                      vil støtte opp om:
                    </p>

                    <div className="columns">
                      <div className="column text-center">
                        <img src={un_goal_image1} />
                      </div>
                      <div className="column text-center">
                        <img src={un_goal_image2} />
                      </div>
                      <div className="column text-center">
                        <img src={un_goal_image3} />
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column text-center">
                        <img src={un_goal_image4} />
                      </div>
                      <div className="column text-center">
                        <img src={un_goal_image5} />
                      </div>
                      <div className="column text-center">
                        <img src={un_goal_image6} />
                      </div>
                    </div>

                    <p>
                      Les mer om FNS bærekraftsmål{" "}
                      <a href="https://www.fn.no/om-fn/fns-baerekraftsmaal">
                        her
                      </a>
                      , og deres retningslinjer.
                    </p>
                  </div>
                  </div>

<div className="column">
</div>
</div>
</div>
</div>
</section>

    <footer>
      {renderFooterAction(page_name)}
    </footer>
  </main>
  )
}

FnPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  page_name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  un_goal: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const FnPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FnPageTemplate
        page_name={post.frontmatter.page_name}
        image={post.frontmatter.image}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        content={post.frontmatter.content}
        un_goal={post.frontmatter.un_goal}
      />
    </Layout>
  )
}

FnPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FnPage

export const FnPageQuery = graphql`
  query FnPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        page_name
        title
        description
        content
        un_goal {
          image_1 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_2 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_3 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_4 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_5 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_6 {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
